import { QueryObserverBaseResult, useQuery } from '@tanstack/react-query';
import { ReactNode, createContext, useContext } from 'react';
import { APIListResponse, getData, getOrganisationID } from 'APIHandler';
import { getOrganisation, getOrganisationAttributes, remapAttributes } from 'api/wrappers/OrganisationAPI';
import { Organisation, OrganisationAttributeType, OrganisationAttributesType } from 'types/organisations';
import { formatWildcardURL } from 'helpers/Utils';
import Loader from 'components/UI/Loader/Loader';

export const defaultOrganisationAttributes: OrganisationAttributesType = {
  band: { key: 'band', label: 'Band', type: 'pre-populated' },
  department: { key: 'department', label: 'Department', type: 'pre-populated' },
  gender: { key: 'gender', label: 'Gender', type: 'pre-populated' },
  geo: { key: 'geo', label: 'Geo', type: 'pre-populated' },
  location: { key: 'location', label: 'Location', type: 'pre-populated' },
  role: { key: 'role', label: 'Role', type: 'pre-populated' },
  startedat: { key: 'startedat', label: 'StartDate', type: 'datetime' },
};

export interface OrgPresignedLogos {
  presignedDarkModeLogo: string;
  presignedDarkModeFavicon: string;
  presignedLightModeLogo: string;
  presignedLightModeFavicon: string;
}

interface OrgContextType {
  organisation: Organisation & OrgPresignedLogos;
  attributes: APIListResponse<OrganisationAttributeType>;
  attributesObj: OrganisationAttributesType;
  isHulerOrganisation: boolean;
  refetchAttributes: () => Promise<QueryObserverBaseResult>;
  refetchOrganisation: () => Promise<QueryObserverBaseResult>;
}

export const OrgContext = createContext<OrgContextType>({
  organisation: {} as Organisation & OrgPresignedLogos,
  attributes: { items: [], itemCount: 0 },
  attributesObj: {} as OrganisationAttributesType,
  isHulerOrganisation: false,
  refetchAttributes: (() => {}) as () => Promise<QueryObserverBaseResult>,
  refetchOrganisation: (() => {}) as () => Promise<QueryObserverBaseResult>,
});

interface OrgContextProps {
  children: ReactNode;
}

export const OrgContextProvider = ({ children }: OrgContextProps) => {
  const path = getOrganisationID();
  const organisation = useQuery<Organisation & OrgPresignedLogos>({
    queryKey: ['organisation', path],
    queryFn: () =>
      getOrganisation(path).then(async (res) => {
        const readPresignURL = await getData('ReadPresignAPI', '');

        return {
          ...res,
          presignedDarkModeLogo: formatWildcardURL({ imgKey: res.darkModeLogoKey, presignWildcardURL: readPresignURL.wildcardUrl, type: 'branding' }),
          presignedDarkModeFavicon: formatWildcardURL({
            imgKey: res.darkModeFaviconKey,
            presignWildcardURL: readPresignURL.wildcardUrl,
            type: 'branding',
          }),
          presignedLightModeLogo: formatWildcardURL({
            imgKey: res.lightModeLogoKey,
            presignWildcardURL: readPresignURL.wildcardUrl,
            type: 'branding',
          }),
          presignedLightModeFavicon: formatWildcardURL({
            imgKey: res.lightModeFaviconKey,
            presignWildcardURL: readPresignURL.wildcardUrl,
            type: 'branding',
          }),
        };
      }),
  });
  const attributes = useQuery<APIListResponse<OrganisationAttributeType>>({
    queryKey: ['organisationAttributes', path],
    queryFn: () => getOrganisationAttributes(),
  });

  if (!organisation.data || !attributes.data) return <Loader fullscreen />;
  return (
    <OrgContext.Provider
      value={{
        organisation: organisation.data,
        attributes: attributes.data,
        isHulerOrganisation: organisation.data.path === 'huler',
        attributesObj: remapAttributes(attributes.data.items) as OrganisationAttributesType,
        refetchAttributes: () => attributes.refetch(),
        refetchOrganisation: () => organisation.refetch(),
      }}
    >
      {children}
    </OrgContext.Provider>
  );
};

export const useOrg = () => {
  const context = useContext(OrgContext);

  if (context === undefined) throw new Error('useOrg must be used within a OrgContextProvider');

  return context;
};

export default OrgContext;
