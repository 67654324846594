import { getOrganisationID } from 'APIHandler';

export const GatewayID = process.env.REACT_APP_API_GATEWAY_ID;

interface APIEndpoints {
  AppAPI?: string;
  UsersAPI?: string;
  UserUploadAPI?: string;
  CompositeUsersAPI?: string;
  OrganisationAPI?: string;
  CompositeOrganisationAPI?: string;
  ReportingAPI?: string;
  TilesAPI?: string;
  CompositeTilesAPI?: string;
  AudiencesAPI?: string;
  ReadPresignAPI?: string;
  WritePresignAPI?: string;
  SharedDataPresignAPI?: string;
  CompositeAudiencesAPI?: string;
  AntivirusAPI?: string;
  TranscodeAPI?: string;
  FeaturesAPI?: string;
  CompositeFeaturesAPI?: string;
  ScormAPI?: string;
  NotificationsAPI?: string;
  CompositeNotificationsAPI?: string;
  BookmarksAPI?: string;
  ScrapeAPI?: string;

  // Bespoke
  RecognitionAPI?: string;
  JournalsAPI?: string;

  // CrazyDiamond
  TickerAPI?: string;
}

const endpoints = (organisationId: string): APIEndpoints => ({
  AppAPI: GatewayID,
  UsersAPI: `${GatewayID}users/${organisationId}/user/`,
  UserUploadAPI: `${GatewayID}users/${organisationId}/usercsv`,
  CompositeUsersAPI: `${GatewayID}users/${organisationId}/composite`,
  OrganisationAPI: `${GatewayID}organisation/${organisationId}/`,
  CompositeOrganisationAPI: `${GatewayID}organisation/${organisationId}/composite`,
  ReportingAPI: `${GatewayID}reporting/${organisationId}/`,
  TilesAPI: `${GatewayID}tiles/${organisationId}/tile/`,
  CompositeTilesAPI: `${GatewayID}tiles/${organisationId}/composite`,
  ScrapeAPI: `${GatewayID}tiles/${organisationId}/scrape`,
  FeaturesAPI: `${GatewayID}features/${organisationId}/`,
  CompositeFeaturesAPI: `${GatewayID}features/${organisationId}/composite`,
  AudiencesAPI: `${GatewayID}audiences/${organisationId}/`,
  CompositeAudiencesAPI: `${GatewayID}audiences/${organisationId}/composite`,
  ReadPresignAPI: `${GatewayID}misc/${organisationId}/readpresign`,
  WritePresignAPI: `${GatewayID}misc/${organisationId}/writepresign`,
  SharedDataPresignAPI: `${GatewayID}misc/${organisationId}/shareddatapresign`,
  AntivirusAPI: `${GatewayID}antivirus/${organisationId}`,
  TranscodeAPI: `${GatewayID}audiovideo/${organisationId}/transcode`,
  ScormAPI: `${GatewayID}scorm/${organisationId}/`,
  NotificationsAPI: `${GatewayID}notification/${organisationId}/`,
  CompositeNotificationsAPI: `${GatewayID}notification/${organisationId}/composite`,
  BookmarksAPI: `${GatewayID}tiles/${organisationId}/bookmarks/`,

  // Bespoke
  RecognitionAPI: `${process.env.REACT_APP_KUDOS_ENDPOINT}${organisationId}/`,
  JournalsAPI: `${process.env.REACT_APP_JOURNALS_ENDPOINT}/`,

  // CrazyDiamond
  TickerAPI: `${process.env.REACT_APP_TICKER_ENDPOINT}${organisationId}/`,
});

export type APIName = keyof APIEndpoints;

export const getEndpoint = (apiName: APIName, path = ''): string => {
  const orgId = getOrganisationID();
  const endpoint = endpoints(orgId)[apiName];

  return endpoint + path;
};
