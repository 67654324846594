import {
  CompletionCSVCustomColumns,
  CompletionCSVCustomColumnsById,
  CSVCustomColumnType,
  CSVCustomColumnTypeById,
  MandatoryCSVCustomColumns,
  MandatoryCSVCustomColumnsById,
  PopularTilesCSVCustomColumns,
  PopularTilesCSVCustomColumnsById,
  ScormCSVCustomColumns,
  ScormCSVCustomColumnsById,
  TileInteractionsCSVCustomColumns,
  TileInteractionsCSVCustomColumnsById,
} from 'types/reports';
import { BasicAdminColumnFieldType } from 'components/Table/tableColumnFields';
import { formatDateNumbersOnly } from './Utils';

export const reportDate = formatDateNumbersOnly(new Date());

// BE expects Heading,value pairs - 'Tile Name,tile.name,Custom Heading,tile.type'
const convertCSVColumnsToString = (columns: CSVCustomColumnType[]): string => {
  return columns.map((column) => `${column.heading},${column.value}`).join(',');
};

export const getCsvParams = (columns: CSVCustomColumnType[], csvFileName?: string) => {
  return {
    columns: columns ? convertCSVColumnsToString(columns) : undefined,
    csvFileName: csvFileName ? csvFileName + `_${reportDate}` : undefined,
  };
};

export const getCSVColumnsData = <ColumnType extends CSVCustomColumnType, ColumnIdType extends CSVCustomColumnTypeById>(
  columnFields: BasicAdminColumnFieldType[],
  csvData: ColumnIdType[],
  splitData?: { [key: string]: ColumnType[] },
  // splitData property is an override property which allows you to insert data where an id would usually go
  // For example, if the id is createdBy, then insert 2 columns for createdByName and createdByEmail
): ColumnType[] => {
  const columnsToDownload: ColumnType[] = [];

  columnFields.forEach((column) => {
    if (splitData && column.id in splitData) {
      splitData[column.id].forEach((dataColumn) => columnsToDownload.push(dataColumn));
    } else {
      const dataItem = csvData.find((item) => column.id === item.id);
      if (dataItem) {
        columnsToDownload.push({
          heading: column.label,
          value: dataItem.value,
        } as ColumnType);
      }
    }
  });

  return columnsToDownload;
};

export const getPopularTilesCSVColumns = (
  columnFields: BasicAdminColumnFieldType[],
  csvData: PopularTilesCSVCustomColumnsById[],
): PopularTilesCSVCustomColumns[] => {
  const splitData: { [key: string]: PopularTilesCSVCustomColumns[] } = {
    createdBy: [
      {
        heading: 'Created By Name',
        value: 'tile.createdBy.fullName',
      },
      {
        heading: 'Created By Email',
        value: 'tile.createdBy.email',
      },
    ],
  };
  return getCSVColumnsData<PopularTilesCSVCustomColumns, PopularTilesCSVCustomColumnsById>(columnFields, csvData, splitData);
};

export const getTileInteractionsCSVColumns = (
  columnFields: BasicAdminColumnFieldType[],
  csvData: TileInteractionsCSVCustomColumnsById[],
): TileInteractionsCSVCustomColumns[] => {
  const splitData: { [key: string]: TileInteractionsCSVCustomColumns[] } = {
    clickedBy: [
      {
        heading: 'Clicked By Name',
        value: 'event.createdBy.fullName',
      },
      {
        heading: 'Clicked By Email',
        value: 'event.createdBy.email',
      },
    ],
  };
  return getCSVColumnsData<TileInteractionsCSVCustomColumns, TileInteractionsCSVCustomColumnsById>(columnFields, csvData, splitData);
};

export const getScormCSVColumns = (columns: BasicAdminColumnFieldType[], csvData: ScormCSVCustomColumnsById[]) => {
  return getCSVColumnsData<ScormCSVCustomColumns, ScormCSVCustomColumnsById>(columns, csvData);
};

export const getCompletionCSVColumns = (columns: BasicAdminColumnFieldType[]) => {
  // Ids must match up with columnFields data
  const customCSVFieldsData: CompletionCSVCustomColumnsById[] = [
    {
      id: 'itemName',
      value: 'tile.name',
    },
    {
      id: 'itemType',
      value: 'tile.type',
    },
    {
      id: 'userName',
      value: 'user.fullName',
    },
    {
      id: 'userEmail',
      value: 'user.email',
    },
    {
      id: 'completionStatus',
      value: 'completion.isPassed',
    },
    {
      id: 'completionDate',
      value: 'completion.createdAt',
    },
  ];

  const splitData: { [key: string]: CompletionCSVCustomColumns[] } = {
    completionStatus: [
      {
        heading: 'Is Completed',
        value: 'completion.isCompleted',
      },
      {
        heading: 'Is Passed',
        value: 'completion.isPassed',
      },
    ],
  };

  return getCSVColumnsData<CompletionCSVCustomColumns, CompletionCSVCustomColumnsById>(columns, customCSVFieldsData, splitData);
};

export const getMandatoryCSVColumns = (columns: BasicAdminColumnFieldType[]) => {
  // Ids must match up with columnFields data
  const customCSVFieldsData: MandatoryCSVCustomColumnsById[] = [
    {
      id: 'itemName',
      value: 'tile.name',
    },
    {
      id: 'itemType',
      value: 'tile.type',
    },
    {
      id: 'userName',
      value: 'user.fullName',
    },
    {
      id: 'userEmail',
      value: 'user.email',
    },
    {
      id: 'enrolmentDate',
      value: 'enrolledAt',
    },
    {
      id: 'updatedAt',
      value: 'progress.lastAccessedAt',
    },
    {
      id: 'completionStatus',
      value: 'completion.status',
    },
    {
      id: 'completionDate',
      value: 'completion.createdAt',
    },
  ];

  return getCSVColumnsData<MandatoryCSVCustomColumns, MandatoryCSVCustomColumnsById>(columns, customCSVFieldsData);
};
