import { css } from 'styled-components';

const sharedVariables = css`
  --huler-logo: url(/img/branding/huler-logo.svg);
  --primary-color: #fb6664;
  --primary-color-readable: #f95853;
  --primary-color-rgb: 252, 102, 101;
  --light-mode-primary: #fb6664;
  --light-mode-primary-rgb: 252, 102, 101;
  --dark-mode-primary: #fb6664;
  --dark-mode-primary-rgb: 252, 102, 101;
  --huler-orange-light: '';
  --glow: 0 0px 20px 4px var(--primary-color);

  --input-dropdown-height: 300px;
  --input-height: 1.5em;
  --input-width-sm: 230px;
  --input-width-md: 330px;
  --input-width-lg: 550px;
  --admin-header-height: 85px;
  --admin-sidebar-width: 240px;

  // Font weights
  --sofia-black: 900;
  --sofia-bold: 700;
  --sofia-semibold: 600;
  --sofia-medium: 500;
  --sofia-regular: 400;
  --sofia-light: 300;

  // Greys
  --grey-10: #f5f5f5;
  --grey-10-rgb: 245, 245, 245;
  --grey-20: #ededed;
  --grey-20-rgb: 237, 237, 237;
  --grey-30: #e9e9ea;
  --grey-40: #e5e6e9;
  --grey-60: #e4e4e4;
  --grey-60-rgb: 288, 288, 288;
  --grey-70: #ddddde;
  --grey-70-rgb: 221, 221, 222;
  --grey-80: #b8b8b8;
  --grey-80-rgb: 184, 184, 184;
  --grey-100: #807e84;
  --grey-200: #515159;
  --grey-200-rgb: 81, 81, 89;
  --grey-300: #413f49;
  --grey-500: #2e2c35;
  --grey-500-rgb: 46, 44, 53;
  --grey-600: #212028;
  --grey-700: #1c1b22;
  --grey-700-rgb: 28, 27, 34;
  --grey-800: #17161c;
  --grey-800-rgb: 23, 22, 28;
  --grey-850: #131319;
  --grey-900: #101014;
  --grey-900-rgb: 16, 16, 20;

  --green-100: #6ad194;
  --green-200: #3cbc70;

  --red-100: #df315a;
  --yellow-300: #d5a816;
`;

const lightVariables = css`
  // Dynamic properties
  --primary-color: var(--light-mode-primary);
  --primary-color-rgb: var(--light-mode-primary-rgb);
  --contrast: black;
  --contrast-rgb: 0, 0, 0;

  // Elements
  --background: var(--grey-10);
  --background-rgb: var(--grey-10-rgb);
  --panel-background: white;
  --footer-background: rgba(255, 255, 255, 0.9);
  --modal-container: rgba(152, 152, 152, 0.6);
  --modal-inner: rgba(255, 255, 255, 0.85);
  --background-title: var(--grey-60);
  --scrollbar: var(--grey-300);
  --border: var(--grey-80);
  --border-lighter: var(--grey-60);
  --bookmark-input-background: var(--grey-10);
  --shared-url-background: white;
  --shared-url-border: var(--grey-80);
  --unseen-notification: var(--grey-30);
  --text-editor-toolbar-background: var(--grey-80);
  --admin-nav-dropdown-shadow: 0 0 10px var(--grey-30);
  --bubble-background: var(--grey-30);
  --badge-background: var(--light-mode-primary);
  --badge-text: white;
  --focused-message-background: var(--grey-10);
  --admin-header-border: var(--grey-70);
  --actions-menu-2: white;
  --admin-dashboard-tile-background: white;
  --subtle-accent: var(--grey-30);
  --drag-handle: var(--grey-80);

  // Tiles
  --empty-tile-background: white;
  --placeholder-tile-background: var(--grey-40);
  --placeholder-user-card-background: var(--grey-20);
  --placeholder-user-card-skeleton: var(--grey-60);
  --placeholder-user-card-skeleton-faded: var(--grey-10);
  --placeholder-tile-ui-color: var(--grey-10);
  --empty-collection-background-1: white;
  --empty-collection-background-2: var(--grey-20);
  --empty-collection-background-3: var(--grey-30);
  --empty-collection-background-4: var(--grey-60);

  // Buttons
  --circle-button-border: var(--grey-80);
  --secondary-button-background: var(--grey-80);
  --secondary-button-color: var(--grey-900);
  --outline-button-border: black;
  --outline-button-background: black;
  --outline-button-color: black;
  --outline-button-color-hover: white;
  --grey-outline-button-border: var(--grey-80);
  --grey-outline-button-color: var(--grey-900);
  --grey-outline-button-color-hover: var(--grey-900);
  --grey-outline-button-background-hover: var(--grey-70);
  --grey-outline-button-border-hover: var(--grey-70);
  --toggle-off-background: var(--grey-60);
  --toggle-on-background: white;
  --toggle-knob-background: white;
  --soft-button-color: var(--grey-80);
  --admin-circle-button-background: var(--grey-60);
  --admin-circle-button-background-hover: var(--grey-20);
  --admin-circle-button-color: var(--grey-100);
  --admin-circle-button-color-hover: black;
  --admin-circle-button-border: var(--grey-80);
  --admin-circle-button-border-hover: var(--grey-100);
  --admin-sidebar-link-background-active: var(--grey-30);
  --admin-sidebar-link-background-hover: var(--grey-10);
  --admin-sidebar-link-shadow: #5151593c;
  --radio-toggle-border: var(--grey-80);
  --file-viewer-toolbar-button-background-hover: var(--grey-60);
  --file-viewer-error-img-bg: var(--grey-20);
  --file-viewer-error-icon: var(--grey-80);
  --settings-tray-square-button-background: var(--grey-30);
  --square-icon-selection-button-background-secondary: var(--grey-70);

  // Forms
  --select-options-background: white;
  --select-options-option-background: var(--grey-60);
  --select-options-option-background-hover: var(--grey-20);
  --select-options-option-circle: var(--grey-80);
  --select-options-option-add: var(--grey-80);
  --select-options-option-remove: var(--grey-200);
  --select-options-scrollbar: var(--grey-20);
  --selected-option-background: var(--grey-30);
  --label-color: var(--grey-200);
  --label-color-active: var(--grey-900);
  --radio-button-background: var(--grey-30);
  --search-input-border: var(--grey-80);
  --search-input-placeholder: var(--grey-900);
  --search-input-text: var(--grey-900);
  --admin-input-border: var(--border);
  --admin-input-placeholder: var(--grey-80);
  --square-button-background: white;
  --toggle-input-background: var(--grey-70);
  --toggle-input-thumb-background: white;
  --toggle-input-description: var(--grey-80);
  --radio-toggle-text: var(--grey-900);

  // Shadows
  --soft-button-shadow: -2px -2px 5px #ffffff30, -2px -2px 5px #ffffff30, 0 0 15px #ffffff30, 5px 5px 10px #00000010;
  --hover-button-shadow: -3px -3px 6px #ffffff30, -3px -3px 6px #ffffff30, 0 0 16px #ffffff30, 6px 6px 11px #00000010;
  --square-button-shadow: -3px -3px 5px #ffffffb3, 4px 4px 6px #00000033;
  --square-button-shadow-hover: -4px -4px 10px #ffffffe6, 5px 5px 7px #0000004d;
  --admin-background-shadow: 8px 8px 13px #00000008, -8px -8px 13px #00000008;
  --tile-shadow-color: rgba(0, 0, 0, 0.4);
  --focused-message-box-shadow: 10px 10px 16px #51515929;
  --user-card-shadow-color: #51515929;

  // Text
  --title-color: black;
  --body-text-color: black;
  --secondary-text-color: var(--grey-100);
  --hub-welcome-text-color: var(--grey-80);

  // Tables
  --table-row-border: var(--grey-60);
  --table-row-background-hover: var(--grey-30);
  --table-row-background-locked: var(--grey-20);
  --table-row-background-sticky: var(--grey-20);
  --table-actions-background: white;
  --table-actions-shadow: var(--grey-70);

  // User Search
  --user-search-background-2: var(--grey-20);

  // User Cards
  --user-cards-contrast: var(--grey-80);

  // Reports user tile
  --reports-user-tile-intro-text: var(--grey-80);
  --reports-user-tile-sub-title: var(--grey-200);
  --reports-user-tile-created: var(--grey-900);
  --reports-user-tile-icon: var(--grey-900);

  // Collection Info
  --collection-info-dropdown-shadow: 0px 0px 26px #51515929;

  // Icons
  --icon-contrast-1: var(--grey-900);

  // Collection Quick Add URL
  --collection-quick-add-url-background: var(--grey-10);
  --collection-quick-add-url-border: var(--grey-80);
  --collection-quick-add-url-color: var(--grey-900);
`;

const darkVariables = css`
  // Dynamic properties
  --primary-color: var(--dark-mode-primary);
  --primary-color-rgb: var(--dark-mode-primary-rgb);
  --contrast: white;
  --contrast-rgb: 255, 255, 255;

  // Elements
  --background: var(--grey-700);
  --background-rgb: var(--grey-700-rgb);
  --panel-background: var(--grey-900);
  --footer-background: rgba(0, 0, 0, 0.9);
  --modal-container: rgba(41, 41, 49, 0.9);
  --modal-inner: rgba(28, 27, 33, 0.7);
  --background-title: var(--grey-900);
  --scrollbar: var(--grey-300);
  --border: var(--grey-300);
  --border-lighter: var(--grey-600);
  --bookmark-input-background: var(--grey-700);
  --shared-url-background: var(--grey-900);
  --shared-url-border: var(--grey-200);
  --unseen-notification: black;
  --text-editor-toolbar-background: var(--grey-500);
  --admin-nav-dropdown-shadow: 0 0 10px var(--grey-800);
  --bubble-background: var(--grey-600);
  --badge-background: var(--dark-mode-primary);
  --badge-text: black;
  --focused-message-background: var(--grey-900);
  --actions-menu-2: var(--grey-500);
  --admin-header-border: var(--grey-900);
  --admin-dashboard-tile-background: var(--grey-800);
  --subtle-accent: var(--grey-900);
  --drag-handle: var(--grey-200);

  // Tiles
  --empty-tile-background: var(--grey-900);
  --placeholder-tile-background: var(--grey-600);
  --placeholder-user-card-background: var(--grey-600);
  --placeholder-user-card-skeleton: var(--grey-700);
  --placeholder-user-card-skeleton-faded: var(--grey-700);
  --placeholder-tile-ui-color: var(--grey-700);
  --empty-collection-background-1: black;
  --empty-collection-background-2: var(--grey-900);
  --empty-collection-background-3: var(--grey-800);
  --empty-collection-background-4: var(--grey-600);

  // Buttons
  --circle-button-border: var(--grey-300);
  --secondary-button-background: white;
  --secondary-button-color: var(--grey-900);
  --outline-button-border: white;
  --outline-button-background: white;
  --outline-button-color: white;
  --outline-button-color-hover: black;
  --grey-outline-button-border: var(--grey-500);
  --grey-outline-button-color: white;
  --grey-outline-button-color-hover: white;
  --grey-outline-button-background-hover: var(--grey-500);
  --grey-outline-button-border-hover: var(--grey-500);
  --toggle-off-background: var(--grey-900);
  --toggle-on-background: var(--grey-300);
  --toggle-knob-background: var(--grey-700);
  --soft-button-color: var(--grey-200);
  --admin-circle-button-background: var(--grey-500);
  --admin-circle-button-background-hover: var(--grey-600);
  --admin-circle-button-color: var(--grey-100);
  --admin-circle-button-color-hover: white;
  --admin-circle-button-border: var(--grey-300);
  --admin-circle-button-border-hover: var(--grey-500);
  --admin-sidebar-link-background-active: var(--grey-300);
  --admin-sidebar-link-background-hover: var(--grey-600);
  --admin-sidebar-link-shadow: var(--grey-900);
  --radio-toggle-border: white;
  --file-viewer-toolbar-button-background-hover: var(--grey-200);
  --file-viewer-error-img-bg: var(--grey-700);
  --file-viewer-error-icon: var(--grey-200);
  --settings-tray-square-button-background: var(--grey-500);
  --square-icon-selection-button-background-secondary: var(--grey-500);

  // Forms
  --select-options-background: var(--grey-500);
  --select-options-option-background: var(--grey-700);
  --select-options-option-background-hover: var(--grey-900);
  --select-options-option-circle: #414049;
  --select-options-option-add: white;
  --select-options-option-remove: var(--grey-500);
  --select-options-scrollbar: var(--grey-700);
  --selected-option-background: var(--grey-700);
  --label-color: var(--grey-100);
  --label-color-active: var(--grey-80);
  --radio-button-background: var(--grey-300);
  --search-input-border: var(--grey-200);
  --search-input-placeholder: white;
  --search-input-text: white;
  --admin-input-border: var(--border);
  --admin-input-placeholder: var(--grey-200);
  --square-button-background: var(--grey-900);
  --toggle-input-background: var(--grey-900);
  --toggle-input-thumb-background: var(--grey-600);
  --toggle-input-description: var(--grey-300);
  --radio-toggle-text: white;

  // Shadows
  --soft-button-shadow: -2px -2px 5px #65656d1f, -2px -2px 5px #65656d1f, 0 0 15px #65656d1f, 5px 5px 10px #00000057;
  --hover-button-shadow: -5px -5px 8px #65656d1f, -5px -5px 8px #65656d1f, 0 0 18px #65656d1f, 8px 8px 13px #00000057;
  --square-button-shadow: -3px -3px 5px #b8b8b81f, 5px 5px 10px #00000066;
  --square-button-shadow-hover: -4px -4px 8px #b8b8b82e, 5px 5px 7px #0000004d;
  --admin-background-shadow: 8px 8px 13px #0000001c, 8px 8px 13px #00000080;
  --tile-shadow-color: #000000;
  --focused-message-box-shadow: 10px 10px 16px #00000029;
  --user-card-shadow-color: #00000029;

  // Text
  --title-color: white;
  --body-text-color: white;
  --secondary-text-color: var(--grey-80);
  --hub-welcome-text-color: var(--grey-200);

  // Tables
  --table-row-border: var(--grey-500);
  --table-row-background-locked: var(--grey-600);
  --table-row-background-hover: var(--grey-500);
  --table-row-background-sticky: var(--grey-800);
  --table-actions-background: var(--grey-800);
  --table-actions-shadow: var(--grey-900);

  // User Search
  --user-search-background-2: var(--grey-700);

  // User Cards
  --user-cards-contrast: var(--grey-200);

  // Collection Summary
  --reports-user-tile-intro-text: var(--grey-100);
  --reports-user-tile-sub-title: var(--grey-80);
  --reports-user-tile-created: var(--body-text-color);
  --reports-user-tile-icon: var(--grey-500);

  // Collection Info
  --collection-info-dropdown-shadow: 0px 0px 26px #0000005c;

  // Icons
  --icon-contrast-1: white;

  // Collection Quick Add URL
  --collection-quick-add-url-background: var(--grey-700);
  --collection-quick-add-url-border: var(--grey-300);
  --collection-quick-add-url-color: var(--grey-80);
`;

export { sharedVariables, lightVariables, darkVariables };
