import { ImageCategoryKey } from 'components/CreateTile/categorisedImages';

export const myTilesID = '00000000-0000-0000-0001-000000000000';
export const sharedTilesID = '00000000-0000-0000-0002-000000000000';
export const workplaceID = '00000000-0000-0000-0000-000000000001';
export const carouselID = '00000000-0000-0000-0000-000000000003';
export const featuredID = '00000000-0000-0000-0000-000000000002';
export const importedContentID = '00000000-0000-0000-0003-000000000006';
export const learnID = '00000000-0000-0000-0000-000000000006';
export const globalAudienceID = '00000000-0000-0001-0000-000000000000';
export const imageBankCollectionID = '00000000-0000-0000-0000-000000000004';
export const imageBankBrandCollectionID = '00000000-0000-0000-0000-000000000005';

export const twoMB = 2097152;
export const fifteenMB = 15728640;

export const systemUserID = '00000000-0000-0000-0000-000000000000';

export const learnContentID = '00000000-0000-0000-0003-000000000001';
export const learnItemID = '00000000-0000-0000-0003-000000000002';
export const learnTrendingID = '00000000-0000-0000-0003-000000000003';
export const learnFeaturedID = '00000000-0000-0000-0003-000000000004';
export const learnImageCategories: ImageCategoryKey[] = ['Compliance', 'Culture', 'Development', 'Office', 'People', 'Tech'];

export const maxTileTitleLength = 50;
export const maxTileDescriptionLength = 300;
export const carouselTileLimit = 10;

export const maxInteger = Number.MAX_SAFE_INTEGER;

// Date formatters
export const isoDate = 'YYYY-MM-DD';
export const isoDateTime = 'YYYY-MM-DDTHH:mm:ss';
export const isoDateTimeUTC = 'YYYY-MM-DDTHH:mm:ss[Z]';
